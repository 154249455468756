.table-mode-info {
    .expansion-panel {
        overflow: auto;
    }

    .details {
        display: flex;
        flex-direction: column;
    }

    .project-select {
        width: 70%;
        align-self: center;
    }

    .bottom-wrapper {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        justify-content: space-around;
    }

    .MuiCardHeader-root {
        padding-bottom: 0px !important;
    }

    .datepicker-wrapper {
        text-align: center;
    }

    .notification-error {
        background-color: rgba(189, 0, 12, 0.87) !important;
    }

    .notification__content {
        display: flex;
        align-items: center;
    }

    .notification__icon {
        margin-right: 10px;
    }
}