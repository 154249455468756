.billable-periods {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .top-block {
    width: 600px;

    .calendar-block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .calendar-block-title {
        width: 100px;
          font-size: large;
          font-weight: 400;
          margin-right: 2px;
      }

      .calendar-block-element {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;

        .react-datepicker-wrapper {
          width: 137px !important
        }
      }
    }

    .selects-block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 5%;

      .manage-first-element {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;

        .manager-text {
          width: 100px;
          font-size: large;
          font-weight: 400;
        }

        .myCustomPrefix__value-container {
          min-width: 100px !important;
        }
      }

      .manage-second-element {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;

        .total-hours-text {
          width: 100px;
          font-size: large;
          font-weight: 400;
        }

        .text-field {
          width: 138px !important;
        }
      }
    }

    .manage-thrid-element {
      margin-top: 5%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;

      .invoice-text {
        width: 100px;
        font-size: large;
        font-weight: 400;
      }

      .text-field {
        width: 100% !important;
      }

    }
  }

  .middle-block {
    width: 900px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-top: 50px;

    .myCustomPrefix__value-container {
      min-width: 200px !important;
    }
  }

  .bottom-block {
    width: 100%;
    min-width: 900px;
    margin-top: 50px;
    overflow-y: auto;

    .table-block-wrapper {
      width: 100%;

      .table-title {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;

        .delete-icon {
          cursor: pointer;
        }
      }

      .table-container {
        width: 100%;
        overflow: auto;

        table {
          width: 100%;
          display: table;
          border-spacing: 0;
          border-collapse: collapse;

          th {
            white-space: nowrap;
            border: 1px solid lightgray !important;
            text-align: center;
          }

          td {
            white-space: nowrap;
            text-align: center;
            border: 1px solid lightgray !important;
          }

          .table-dev{
            background-color: #D3F1DB;
          }

          .table-total {
            background-color: #FFE2CC;
          }

          .table-weekend {
            background-color: #FEF2CD;
          }
        }
      }

      .table-button {
        width: 100%;
        display: flex;
        justify-content: end;
      }
    }
  }
}