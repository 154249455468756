.billable-hours-projects__table {
    display: table;
    @media only screen and (max-width : 768px) {
      font-size: 0.7rem;
    }
    width:100%;
}

.billable-hours-projects__table-wrap {
    display: flex;
    max-width: 1140px;
    width: 100%;
    padding: 0px 20px;
}

.billable-hours-projects__table-cell {
    width:50%;
}

.billable-hours-projects-wrapper {
    @include split-div(100%);
    margin-top: 2%;
    font-size:16px;
}

.billable-hours-projects__title {
  max-width: 1140px;
  width: 100%;
}