.Settings{
    &-wrapper{
        width: 100%;
        @include flex(flex-start,center);
        flex-direction: column;
    }
    &-main{
        width: 80%;
        height: calc(100vh - 80px);
        @include flex(flex-start,center);
        flex-direction: column;
        padding: 15px;
    }
}