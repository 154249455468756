.jira-worklog-stats-main {

  .projects-nodata {
    padding: 15px;
    font-size: 20px;
    margin-top: 10px;
  }

  .interaction-wrapper {
    display: flex;
  }

  .add-button-wrapper {
    margin-top: 15px;
    margin-right: 15px;
    display: inline-block;
  }

  .select-server-projects {
    margin-top: 10px;
  }

  .working-time {
    margin: 20px;
  }

  .button-top {
    margin-top: 15px;
  }

}