#edit-dialog-chat-select {
  width: 60%;
  margin-top: 15px;
}

.edit-dialog-name {
  width: 50%;
}

.edit-dialog-name-info {
  width: 60%;
  justify-content: center;
  margin: 10px auto !important;
  display: flex !important;
  max-width: 500px;
}

.edit-dialog-manager-select {
  width: 60%;
  margin-top: 15px;
}

.edit-dialog-data-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-dialog-room-select {
  width: 60%;
  margin-top: 15px;
}

.gap {
  padding: 5px;
}

.projects-info-checkboxes {
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px;
}

.projects-info-checkbox {
  margin: 10px;
}

.project-textarea {
  width: 90%;
  align-self: center;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 800px;
}

.project-select {
  width: 70%;
  align-self: center;
  max-width: 500px;
}

.timepicker-wrapper {
  margin: 20px auto 0;
  padding-left: 20px;
  padding-bottom: 20px;

}

.projects-info-header {
  @include card-title;
}

.projects-info__add-sentry {
  width: 150px;
  align-self: center;
  margin: 10px 0;
}

.projects-info__sentry-title {
  @include flex(space-between, center);
  margin-bottom: 5px;
}

.projects-info__sentry-field {
  margin: 5px !important;
}

.projects-info-actions {
  @include flex(space-between);
}

.projects-info-action-button {
  width: 150px
}

.BillableHours-wrapper {

  .react-datepicker__day--disabled {
    color: #ccc !important;
  }

  div.react-datepicker__input-container input {
    width: 40% !important;
    border: none !important;
    cursor: pointer
  }
}