.rbc-selected-cell {
  background-color: white !important;
}

.rbc-event:focus {
  background-color: $blue;
  pointer-events: none;
  outline: none !important;
}

.stats-calendar{
  min-height:460px;
}

.stats-calendar-active{
  height:100%;
}

.left-calendar-div{
  display: block;
  flex: 1;
}

.left-calendar-div+.right-calendar-div  {
  flex: 0 0 auto;
}

.report-text {
  white-space: pre-wrap;
}