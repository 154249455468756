.projects-info-header-actions {
  padding: 15px;
  @include flex(space-between, flex-start);
  flex-wrap: wrap;
}

.projects-info-header-button {
  display: flex !important;
  align-items: center;
  margin: 5px;
}

.projects-info-header-buttons {
  @include flex(flex-end, center);
  flex-wrap: wrap;
}

.projects-info-header-button__icon {
  height: 18px !important;
  width: 18px !important;
  margin-right: 5px;
}