.employee-details {
  @include flex;
}
.employee-details{
  min-height: calc(100% - 50px - 1.25rem);
}
.employee-info-select-div {
  padding-bottom: 1rem;
  align-items: center;
  max-width: 800px;
  justify-content: space-between;
  @include flex;
  flex-wrap: wrap;
}

.employee-details-left-div {
  @include flex;
  flex-direction: column;
  width: 100%;
}

.employee-details-right-div {
  margin-top: 20px;
  margin-left: 2.5%;
  margin-bottom: auto;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;
  .MuiFormControlLabel-root {
    margin-right: 15px;
    margin-left: 0;
  }
}

.reminders-title{
  margin: 0 auto !important;
}

.employee-info-select-description {
  flex: 1 1 23%;
  margin-right: 1%;
}

.employee-info-select {
  flex: 1 1 60%;
  margin-left: 1%;
}

.employee-managers-select {
  flex: 1 1 59%;
  .react-select__control {
    height: 100%;
  }
  .react-select__option{
    font-size:  12px !important;
  }
}

.employee-managers-select-div {
  @include flex;
}

.employee-info-footer {
  @include flex(flex-end, center);


}
.employee-reminder{
  margin: 20px auto 0;
  padding-left: 20px;
}

.employee-update-button {
  min-width: 150px;
}

.notification-success {
  background-color: green !important;
}

.notification-error {
  background-color: rgba(189, 0, 12, 0.87) !important;
}

.notification__content {
  display: flex;
  align-items: center;
}

.notification__icon {
  margin-right: 10px;
}