.main-wrapper {
    .header {
        position: fixed;
        z-index: 9999;
        width: 100%;
        flex: 0 0 100%;
        text-align: justify;
        height: 70px;
    }

    .main-page {
        padding: 80px 10px 10px 10px;
        display: flex;
        flex-flow: row wrap;
        height: 100vh;
    }
}

