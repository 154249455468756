.employees-nodata {
    padding: 15px;
    font-size: 20px;
}

.table-mode-main {
    width: 100%;
    min-height: calc(100vh - 80px);
    @include flex(flex-start,center);
    flex-direction: column;
}

.table-mode-button {
    padding-left: 0 !important;
}