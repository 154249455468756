.billable-hours-stats__wrapper {
  display: flex;
  flex-direction: column;
}

.billable-hours-stats__datepicker__wrapper {
  padding: 10px;
  display: flex;
  flex-direction: row;
}

.billable-hours-stats__select__wrapper {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-around;
}

.billable-hours-stats__load-button__wrapper {
  padding: 10px;
  width: 200px;
}

.billable-hours-stats__select__wrapper > div {
  width: 300px;
}

.billable-hours-stats__stats__wrapper {
  padding: 20px;
  font-size: 1rem;
}