body{
    height: 100% !important;
}
.login-main {
    background: white !important;
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    flex: 1 !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    box-sizing: initial !important;
}

.login-wrapper {
    width: 300px !important;
    padding: 20px !important;
    display: flex !important;
    flex-direction: column !important;
    box-sizing: initial !important;
}

.form-control {
    margin-bottom: 20px !important;
    padding: 0 !important;
    border: none !important;
}

.form-element {
    width: 100% !important;
}
