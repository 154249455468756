.root-milestones{
  width: 100%;
}
.milestones-main {
  background-color: $background-color;
  width: 100%;
  height: calc(100vh - 80px);
  @include flex(center);
  flex-flow: row wrap;
}

.milestones-wrapper{
  @include split-div(100%);
  margin-top: 2%;
  font-size:16px;

  .circular { 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    width: 100%;
    height: 300px;
  }
}

.milestone-project-item-card{
  border-radius: 0.75rem !important;
}

.milestone-search-font{
  font-size:14px !important;
}

.milestone-project-item-data{
  min-width: 120px;
}

.milestones__no-data {
  text-align: center;
}

@media(max-width: 576px){
  .custom-wrap{
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
  }
}

