.billing-summary {
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .top-block {
    width: 900px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .manage {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      z-index: 2;

      .manage-first-element {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 50%;

        .myCustomPrefix__value-container > div {
          width: 150px !important;
        }

        span {
          font-size: large;
          font-weight: 400;
          margin-right: 10px;
        }
      }

      .manage-second-element {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 50%;

        .myCustomPrefix__value-container > div {
          width: 50px !important;
        }

        span {
          font-size: large;
          font-weight: 400;
          margin-right: 10px;
        }
      }
    }

    .table-block-wrapper {
      margin-top: 50px;
      height: 200px;

      .table-container {
        max-height: 200px;
        overflow-y: auto;

        table {
          width: 100%;
          display: table;
          border-spacing: 0;
          border-collapse: separate;

          th {
            position: sticky;
            top: 0;
            background-color: lightgray;
            z-index: 1;
            box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }

  .bottom-block {
    width: 900px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 50px;

    .manage {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      z-index: 2;

      .calendar-block-element {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: large;
          font-weight: 400;
          margin-right: 10px;
        }

        .react-datepicker-wrapper {
          width: 150px !important
        }
      }

      .bottom-selects {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: large;
          font-weight: 400;
          margin-right: 10px;
        }

        .myCustomPrefix__value-container {
          min-width: 100px !important;
        }
      }
    }

    .bottom-table {
      .table-block-wrapper {
        .table-container {
          //max-height: 200px;
          max-height: 50%;
          overflow-y: auto;
  
          table {
            width: 100%;
            display: table;
            border-spacing: 0;
            border-collapse: separate;
  
            th {
              position: sticky;
              top: 0;
              background-color: lightgray;
              z-index: 1;
              box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
            }

            .icon {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}