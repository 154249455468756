.header-wrapper {
  width: 100%;
  padding: 15px;
  height: 100%;
  background-color: $foreground-color;
  @include flex;
  @include depth(1);
}

.header-main {
  width: 100%;
  @include maxHeight;
  @include flex;
  flex-direction: row;
  align-items: center;
  font-size: 1rem !important;
  max-width: 1000px;

  @media (max-width: 460px) {
    font-size: 0.8rem !important;
  }

}

.menu-icon {
  height: 18px;
  fill: black;
}

.header-nav {
  display: flex;
  width: 100%;
}

.header-links {
  flex-shrink: 0;

  .nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.header-dropdown-menu {
  border-radius: 0.75rem !important;
}

.header-control {
  @include flex(flex-end, center);
  flex-shrink: 0;
  margin: 0 1rem;

  @media (max-width: 460px) {
    margin: 0 0.5rem;
  }
}

.header-settings{
  margin-left: 0.5rem;
}

.header-menu-button {
  @include flex(center, center);
  cursor: pointer;
  height: 40px;
  width: 40px;
  color: $secondary-color;
}

.language-control{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-size: 1em !important;
  font-weight: 400 !important;
}

.language-dropdown-toggle {
  min-width:45px;
  text-align: center !important;
  padding: 5px 5px !important;
  box-sizing: border-box;
}

.language-dropdown-menu{
  border-radius: 0.75rem !important;
  min-width: 45px !important;
  box-sizing: border-box !important;
  left:0px !important;
}
.language-dropdown-item{
  font-size: 1em !important;
  padding:5px 5px !important;
  text-align: center !important;
  &:active {
    background-color: $blue !important;
  }
}

.username-control {
  color: $text-color;
  font-size: 1.1em !important;
  margin: 0 0.5rem;
  padding: 0 !important;
}

.header-menu-button__main {
  width: 10px;
  height: 12px;
  fill: $primary-color;
  transition: fill $menu-collapse-time;
}

.logout-button {
  white-space: nowrap;
  font-size: 1.1em !important;
  @include button;
}

.header-menu-button__sub {
  height: 12px;
  fill: $secondary-color;
  transition: fill $menu-collapse-time;

  &_showed {
    fill: $primary-color;
  }
}

.header-current-link {
  margin: 0 auto;
}

.header-links-mobile {
  width: 100%;
  display: flex;
  align-items: center;
}

.mobile-menu {
  position: fixed;
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.mobile-menu__links {
  @include flex(center, center);
  flex-direction: column;
  margin-top: 30px;
  font-size: 1.5rem;
}

.header-visible-link {
  padding: 0.5rem 1rem !important;

  @media (max-width: 460px) {
    padding: 0.5rem !important;
  }
}

.header__dropdown-button {
  .glyph-icon {
    color: $primary-color !important;
  }

  &:hover .glyph-icon {
     color: $theme-color-1 !important;
   }
}