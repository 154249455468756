textarea {
  outline: none;
  resize: none;
  overflow: auto;
  width: 100%;
}

code {
  padding: 2px 4px;
  color: #c7254e;
  display: inline-block;
  background-color: #f9f2f4;
  border-radius: 4px;
}

pre code {
  color: inherit;
  background-color: transparent;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}

.details {
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
  padding-top:0px !important;
}

.bottom-wrapper {
  @include flex(space-around);
  margin-top: 20px;
}

.root {
  width: 100%;
}