@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700');
@import './_01_mixins.scss';
@import './_02_variables';
@import './_03_core.scss';
@import './_04_plugins.scss';
@import './_05_views.scss';

/** your custom css code **/
/* 
html {
    background-color: $theme-color-1 !important;
}
*/

.btn {
  font-size: 1rem;
  padding: 0.75rem 1.3rem 0.6rem 1.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
  font-size: 0.9rem;
  line-height: 1.5;
}

.rbc-event {
  font-size:1.25em;
  border-radius: 25px;
}

.calendar-prev-btn,
.calendar-next-btn {
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
}