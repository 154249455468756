.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  color: $theme-color-blue-navy;

  p {
    font-size: 200px;
  }

  span {
    margin-top: 60px;
    font-size: 20px;
  }
  
  .links-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    li {
      list-style-type: none;
      font-size: 20px;
    }
  }
}