.dialog-title {
  font-size: 20px;
}

.dialog-list {
  height: 50vh;
}

.list-item {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between !important;
  align-items: flex-start !important;
  background-color: #145388;
  color: white;
  border-radius: 5px;
  font-size: 15px;
  margin-bottom: 25px;
}

.report-details {
  display: flex;
  flex-direction: row;
}

.report-details-bold {
  font-weight: bold;
  margin-right: 10px;
}

.divider {
  width: 100%;
  background-color: white;
  margin-bottom: 15px;
  margin-top: 15px;
}

.cancel-button {
  color: #145388;
}
