.table-content {
  border-radius: 4px;
  overflow: auto;

  th {
    font-weight: bold;
    color: black;
    padding: 10px 10px;
  }

  td {
    color: black;
    border-top: 1px solid #c3bec3;
    padding: 15px 5px;
  }

  tr:hover td {
    background: #f0faff;
  }

  .expansion-panel {
    overflow: auto;
  }

  .project-select {
    width: 63%;
  }

  .tableWrapper {
    overflow: auto;
    max-height: 440px;
  }

  .MuiToolbar-gutters {
    padding-left: 0px !important;
  }

  .MuiTablePagination-selectRoot {
    margin-right: 10px !important;
  }
}