.dashboard {
  @include card;
  height: auto;
  flex-grow: 1;
}

.reports__no-choice {
  @include card;
  height: max-content;
  align-self: flex-start;
  flex-grow: 1;
}

.card-title-wrapper {
  @include flex(center, center);
  @include card-title;
}

.active-employees-div {
  margin-top: 2%;
}

.card-title {
  padding: 0 1.75rem 0 1.75rem;
  margin-bottom: 0 !important;
}

.controls-wrapper {
  min-height: 100px;
  padding: 10px 0 ;
  height: 100%;
}

.calendar-controls-main {
  min-height: inherit;
  @include flex(center);
  padding-bottom: 10px;
  height: 100%;
}

.calendar-controls {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  width: 100%;
  min-height: 132px;
}

.calendar-controls-left {
  display: flex;
  align-items: flex-start;
  flex: 1 1 auto;
  width: 60%;
  min-width: 300px;

  @media (max-width: 520px) {
    min-width: auto;
  }
}

.calendar-controls-right {
  display: flex;
  align-items: flex-start;
  flex: 1 1 auto;
  width: 40%;
  min-width: 200px;
}

.calendar-select-div {
  width: 80%;
  margin: 20px auto;
}

.calendar-switcher-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: max-content;
  margin: 20px auto;  

  .MuiButton-root {
    border-radius: 40px !important;

    .MuiButton-label {
      color: #2a93d5
    }
  }
}

.calendar-only-active-checkbox {
  margin-top: 20px;
}

.calendar-switcher, .calendar-select {
  height: 48px;

  .react-select__input > input {
    opacity: 1 !important;
  }
}

.calendar-switcher {
  @media (max-width: 520px) {
    display: none !important;
  }
}

.calendar-tabs {
  @media (min-width: 521px) {
    display: none !important;
  }
}

.controls-right-projects {
  @include controls-right(center);
}

.controls-select {
  flex-shrink: 1;
}

.controls-type-selector {
  @include flex(center);
  align-items: center;
  width: 100%;
  height: 100%;
  border-collapse: unset;
  justify-content: center;

  tr{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  th {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    text-align: center;
    cursor: pointer;
    padding: 10px 10px;

    &.selected {
      background: $blue;
      border-color: $blue;
      color: white;
      border-right: 1px solid $blue;
      cursor: default;
    }

    &:first-child {
      @include border-top-radius(left);
    }

    &:last-child {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
      @include border-top-radius(right);
    }
  }

  td {
    &:first-child {
      @include border-top-radius(left);
    }

    &:last-child {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
      @include border-top-radius(right);
    }
  }
}


.calendar-body-message{
  text-align:center;
  font-size: 1.1rem;

}

.stats-wrapper {
  width: 100%;
  @include flex(flex-start);
  flex-flow: row wrap;
  box-sizing: content-box;
}

.split-left {
  @include split-div(36%);
  margin-bottom: 10px;
}

.split-right {
  @include split-div(38%);
  margin-bottom: 10px;
  height: calc(100% - 5.5px);
  overflow-y: hidden;
}

.split-right-wrap {
  height: calc(100% - 5.5px);
  display: flex;
}

.divider {
  padding: 0 1.75rem 0 1.75rem !important;
}
