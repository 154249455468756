
.font-for-select{

  font-size:16px !important;
  &__option{
    font-size: 14px  !important;
  }
  .react_select__option{
    font-size: 14px  !important;
  }
}

.simple-font-for-text{
  font-size:16px !important;
}


.font-for-reminder-label{
  label {
    font-size:16px;
    white-space: nowrap;
  }
}

.checkbox-main{
  font-size:0.95rem;
}