.details {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 !important;
}

.stats-card-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
}

.table-mode-select {
    width: 70%;
    align-self: center;
}

.bottom-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.top-wrapper {
    margin-top: 10px !important;
}

.time-wrapper {
    padding: 15px 15px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-grow: 1;
    align-items: center;
}

.card-header {
    padding-bottom: 0px !important;
}

.table {
    margin-top: 15px !important;
}

.MuiCardContent-root {
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-bottom: 12px !important;
}