.project-access-wrapper {
  margin-top: 15px;
  @include card;
  height: auto;

  .project-access-title {
    text-align: center;
    margin: 1.25rem 0;
  }

  .project-access__select-block {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 30px;
    max-width: 700px;
    margin: auto;
    align-items: center;
  }

  .project-access__select {
    grid-column-start: 1;
    grid-column-end: -2;
  }

  .project-access__button {
    grid-column-start: -2;
  }

  li {
    margin-top: 5px;
  }

  ul {
    margin: 0 auto;
    display: table;
  }
}

