@import '../../variables';

.employees-main {
  background-color: $background-color;
  width: 100%;
  height: calc(100vh - 80px);
  @include flex(flex-start);
  flex-flow: row wrap;
  margin: 0 4%;

  @media (min-device-width: 1600px) {
    width:100%;
    margin: 0 15%;
  }

  @media (max-device-width: 768px) {
    margin: 0;
  }
}

.employee-list {
  @include card;
}

.employee-info {
  @include card;
  height: auto;
}

.employee-info-wrapper, .employee-list-wrapper {
  margin-bottom: 10px;
}

.employee-list-wrapper {
  @include split-div(30%);
}

.employee-name {
  padding-left: 10%;
}

.list-item-divider {
  margin: 0 10% 0 10%;
}

.employee-username {
  color: $light-gray;
}

.employee-controls, .employee-info-title {
  padding-top: 20px;
  padding-bottom: 20px;
}

.employee-controls {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.employee-list-body {
  height: 1px;
  @include scrollable-content;
}

.employee-info-wrapper {
  @include split-div(50%);
}

.employee-text-field-div {
  margin-top: 25px
}

.employee-checkbox-div {
  margin-top: 10px
}

.employee-text-field-div, .employee-select-div {
  @include flex;
  width: 100%;
  align-items: center;
}

.employee-text-field {
  width: 100%;
  height: 100%;

  .MuiFormLabel-root.Mui-focused {
    color: $blue;
  }

  .MuiInput-underline {
    &:after, &:before, &:hover:not(.Mui-disabled):before {
      border-color: $blue;
    }
  }
}

.employee-select-wrapper {
  @include select-div(100%);
  margin: 0;
  padding-top: 16px;
  height: 100%;
}

.employee-select {
  height: 100% !important;
  font-size: 1rem;

  .react-select__control {
    height: 100%;
  }
}

.employee-info-title {
  @include flex(center, center);
  @include card-title;

  .MuiTypography-root {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
}

.employee-info-body {
  min-height: 80%;
  @include flex(center);
  flex-direction: column;
}
