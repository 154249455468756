@import '../../mixins';

.BillableHours{
    &-wrapper{
        @include split-div(100%);
        margin: 1%;
        font-size:16px ;
        min-height: fit-content;
    }
    &-hours{
        @include flex();
    }
    &-table{
        overflow: auto;
    }
    &-cell{
        padding-right: 5px !important;
        padding-left: 5px !important;

        &-billable-time {
            padding-right: 5px !important;
            padding-left: 5px !important;
            background-color: #d4edda !important;
        }
            &-zero-time {
                background-color: #E0E0E0;
            }
        &-totals-old {
            font-weight: bold !important;
            white-space: nowrap !important;
            text-align: center !important;
        }
        &-totals-new {
            font-weight: bold !important;
            white-space: nowrap !important;
            text-align: center !important;
            background-color: #e0f7fa !important;
        }
        &-days-new {
            text-align: center !important;
        }
    }
    &-input{
        width: 65px;
    }
    &-row{
        .BillableHours-cell:first-child{
            padding-left:14px !important;
        }
        .BillableHours-cell:last-child{
            padding-right:14px !important;
        }
    }
    &-control-wrpr {
        display: flex;
        align-items: center;
        justify-content: start;
    }
    &-checkbox {
        padding: 0;
    }
    &-switch {
        display: flex;
        justify-content: flex-end;
    }
    &-header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
}
