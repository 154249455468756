.billable-hours__wrapper {
  display: flex;
  justify-content: center;
  flex: 1 1 100%;
}

.billable-hours__content__wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1140px;
  flex: 1 1 100%;
}

.billable-hours__wrap {
  display: flex;
  max-width: 1140px;
  width: 100%;
  padding: 0px 20px;
}