@mixin maxHeight {
  height: 100%;
}

@mixin flex($content:center, $items: stretch) {
  display: flex;
  justify-content: $content;
  align-items: $items;
}

@mixin controls-right($content) {
  width: 60%;
  @include flex($content);
  flex-direction: column;
  align-items: center;
  border-left: 1px solid $light-gray;
}

@mixin border-top-radius($edge, $radius:6px) {
  @if ($edge == left) {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
  } @else {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  }
}

@mixin split-div($width) {
  padding: 2%;
  min-width: min-content;
  flex: 1 1 $width;
  min-height: 500px;
  box-sizing: border-box;
  
  @media only screen and (max-width : 768px) {
    flex: 1 1 100%;
    padding: 0;
    min-width: 100%;
    min-height: max-content;
  }
}

@mixin select-div($width:80%) {
  font-size: unquote("min(1rem,max(1.2vw, 7px))");
  display: inline-block;
  margin: 1% (100%-$width)/2 1% (100%-$width)/2;
  width: $width;
}

@mixin scrollable-content() {
  height: 200px;
  flex-grow: 1;
  flex-shrink: 1;
  @media all and (max-width: 768px) {
    flex-grow: 0;
    height: max-content;
  }
  .scrollable-content {
    @include flex(flex-start);
    overflow: hidden;
    height: max-content;
    max-height: 100%;

    @media (max-width: 768px) {
      max-height: 50vh;
    }

    .content {
      height: max-content;
      max-height: 100%;
      width: 100%;
    }

    .ps__rail-y {
      position: relative;
    }

    .ps__thumb-y {
      position: relative;
    }
  }
}

@mixin button {
  background-color: $blue !important;
  color: white !important;

  &:hover {
    background-color: #0f3d64 !important;
    border-color: #0f3d64;
  }

  &:disabled {
    background-color: initial !important;
    color: $light-gray !important;
  }
}

@mixin card {
  border-radius: 0.75rem !important;
  height: 100%;

  @media (max-width: 768px) {
    height: max-content;
  }
}

@mixin input-wrapper-styles {
  min-width: 100px;
  position: relative;
  border-radius: 20px;
  background: $background-color;

  input {
    border: initial;
    background: transparent !important;
    outline: initial !important;
    padding: 0.5rem 1rem !important;
    line-height: 1.75;
    font-size: 0.8rem;
    color: $primary-color;
    margin-bottom: 0 !important;
  }
}

@mixin card-title {
  padding: 1.5rem !important;

  h2 {
    margin: 0;
    text-align: center;
  }

}