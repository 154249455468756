.input-block.input-block {
  display: block;
  margin-bottom: 10px;
  @include flex();
  align-items: center;
}
.time-picker-wrapper {
  border: 1px solid $divider-color;
  border-radius: 0.75rem;
  padding: 1.75rem;
  margin: 0 auto;
  flex-direction: row;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px,1fr));
  grid-gap: 30px;
  max-width: 480px;
  width: 100%;
}
#time {
  width: 180px;
}

.reminder{
  @include flex(space-between,center);
  &-add{
    width: fit-content;
    margin: 0 auto !important; 
  }
  &-delete{
    margin-bottom: 10px !important;
    
  }
  &-last{
    padding-right:3rem;
  }
}

.button-back{
  position: absolute !important;
  left: 17px;
  top: 12px;
}