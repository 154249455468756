.update-main {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.update-div {
    text-align: center;
}

.update-text {
    padding: 10px;
}

.update-button {
    display: inline-block;
    padding: .8em;
    border: 1px solid transparent;
    border-radius: 3px;
    background-color: #0095ff;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border-color: #07c;
    box-shadow: inset 0 1px 0 0 rgba(102,191,255,0.75), 0 0 0 0 rgba(0,149,255,0);
    color: #FFF;
}