.alert-notification-wrapper {
  background-color: white;
  border-radius: $border-radius-rounded !important;
  margin-bottom: 1rem;
  box-shadow: 0 0 20px 0 rgba(34, 60, 80, 0.1);
}

.alert-notification {
  border-radius: $border-radius-rounded !important;
  max-width: 400px;
  margin: 0 !important;
}