.table-content {
  border-radius: 4px;
  overflow: auto;

  th {
    font-weight: bold;
    color: black;
    padding: 10px 10px;
    text-align: center;
  }

  td {
    color: black;
    border-top: 1px solid #c3bec3;
    padding: 15px 5px;
    text-align: center;
  }

  tr:hover td {
    background: #f0faff;
  }

  .expansion-panel {
    overflow: auto;
  }

  .project-select {
    width: 63%;
  }

  .tableWrapper {
    overflow: auto;
    max-height: 440px;
  }

  .MuiTablePagination-selectRoot {
    margin-right: 10px !important;
  }

  .table-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    margin: 0 !important;
  }

}