.milestones-add-button-wrapper {
  width: 100%;
  @include flex(center);
  margin-bottom: 20px;
  margin-top: 20px;
}

.milestone-item-wrapper {
  margin: 10px;
  line-height: 1.5;
  text-align: left;
  font-family: "Nunito", sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  color: #303030;
  user-select: none;
  box-sizing: border-box;
  display: flex !important;
  flex-grow: 1 !important;
  padding-left: .5rem !important;
  min-width: 0;
}

.milestone-first-header {
  @include flex(space-between);
  flex-direction: column;
}

.milestone-footer-description {
  background-color: #fff !important;
  border-bottom-left-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.milestone-body-padding {
  padding: 20px 1.75rem !important;
}