.right-card-title {
  @include card-title;
}

.report-text-div {
  width: 100%;

  *{
    font-size: 1rem;
  }
}

.report-param {
  font-weight: bold;
}

.report-wrapper {
  width: 100%;
  padding-top: 1.75rem;
  padding-bottom: 0.75rem !important;
}

.report-date {
  color: $light-gray;
}

.right-card-body {
  height: 100%;
  padding-top: 1.75rem !important;
  @include scrollable-content;

  .content{
    .report-wrapper:first-child{
      padding-top: 0 !important;
    }
  }
}