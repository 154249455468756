@import '../../variables';
@import "../../../themes/gogo.light.blueyale";

.managers-main {
  background-color: $background-color;
  width: 100%;
  @include maxHeight;
  @include flex(flex-start);
  flex-flow: row wrap;
  margin: 0 4%;
  @media (min-width: 1600px) {
    width: 100%;
    margin: 0 15%;
  }

  @media (max-device-width : 768px) {
    margin: 0;
  }
}

.managers-list-wrapper {
  @include split-div(30%);
  @include maxHeight;
  display: flex;
  flex-direction: column;
  min-height: 0;
  max-height: max-content;

  @media (max-width: 768px) {
    height: max-content;
  }
}

.managers-info-wrapper {
  @include split-div(50%);
  min-height: 0;
}

.managers-list, .managers-info, .managers-result-row {
  border-radius: 0.75rem !important;
  margin-bottom: 10px;
  overflow: hidden;
}

.managers-list {
  flex-grow: 0;
  flex-shrink: 0;
}

.managers-result-row {
  cursor: pointer;
  padding: 8px 15px;
  border: 2px solid transparent !important;
  text-align: center;
  transition: border 0.2s ease;

  &:hover, &_selected {
    border: 2px solid $theme-color-1 !important;
  }
}

.managers-result-row__properties {
  display: flex;
}

.managers-result-row__text {
  padding: 0;
  margin: 0;
}

.managers-result-row__subtext {
  padding: 0;
  margin: 0 0 0 10px;
  color: $theme-color-3;

}

.managers-result-row__active-ind {
  @include flex(stretch, center);
  margin-left: auto;
  border-radius: 20px;
  padding: 0 10px;

  background-color: $theme-color-2;
  border-color: $theme-color-2;
  color: $foreground-color;

  &_not-active {
    background-color: $warning-color;
    border-color: $warning-color;
  }
}

.managers-info__title {
  @include card-title;
}
.managers-info__subtitle {
  color: $theme-color-3;
}

.managers-controls {
  width: 100%;
  margin-top: 20px;
}

.managers-list__search-results {
  display: flex;
  flex-direction: column;
  @include scrollable-content;

  @media (max-device-width: 768px) {
    flex-grow: 0;
    height: max-content;
    min-height: 0;
    max-height: 30vh;
  }
}

.managers-list__search-results__no-results {
  padding-top: 10px;
  text-align: center;
}

.managers-list__search-params {
  @include flex(center, flex-end);

  flex-wrap: wrap;
}

.managers-search {
  margin: 0 10px;
  position: relative;
  min-width: 200px;
  width: 200px;
  border-radius: 20px;
  background: $background-color;
  flex-grow: 2;

  input {
    border: initial;
    background: transparent !important;
    outline: initial !important;
    padding: 0.5rem 1rem !important;
    line-height: 1.75;
    font-size: 0.8rem;
    width: 93%;
    color: $primary-color;
    margin-bottom: 0 !important;
  }

  .managers-search-icon {
    font-size: 17px;
    border-radius: 10px;
    color: $separator-color;
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: -10px;
    right: 3px;
    text-align: center;
  }

}

.managers-last-days-container {
  flex-grow: 1;
  min-width: 85px;
  width: 100px;
  margin: 0 10px;
}

.managers-last-days__label {
  text-align: right;
  padding-right: 10px;
  width: 100%;
  color: $theme-color-3;
  font-size: 0.7rem;
}

.managers-last-days {
  position: relative;
  border-radius: 20px;
  background: $background-color;

  input {
    border: initial;
    background: transparent !important;
    outline: initial !important;
    padding: 0.5rem 1rem !important;
    line-height: 1.75;
    font-size: 0.8rem;
    color: $primary-color;
    margin-bottom: 0 !important;
  }
}

.managers-info__table {
  display: table;
  background-color: white;
  @media only screen and (max-width : 768px) {
    font-size: 0.7rem;
  }
}

.managers-info__table_no-hover {
  background-color: initial !important;

  td, tr, th {
    background-color: initial !important;
  }
}

.managers-info__table-row_selected {
  background-color: $separator-color-light;
}

.managers-info__additional-row {
  background-color: $background-color !important;
}

.managers-info-table-container {
  overflow-y: auto;
  background-color: white;
  border-radius: .25rem;
  border: 1px solid rgba(0,0,0,0.125);
}

.show-reports {
  white-space: nowrap;
}